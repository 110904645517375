<template>
    <div class="content">
        <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
        <el-table :data='MerListData' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true'>
             <el-table-column type="expand">
                <template slot-scope="slot">
                    <el-table :stripe='true' :data="DisRulesDataList" :header-cell-style='{background:"#004d8c",color:"#ffffff"}' style="width: calc(100% - 47px)" size="medium">
                        <el-table-column align="center" prop="dr_name" label="优惠券名"></el-table-column>
                        <el-table-column align="center" prop="dr_name" label="剩余额度(张)">
                          <template slot-scope="scope">
                            {{CheckQuota(slot.row,scope.row)}}
                          </template>
                        </el-table-column>
                        <el-table-column align="center" label="操作">
                          <template slot-scope="scope">
                            <el-link type="primary" @click="ReCharge(slot.row,scope.row)">充值</el-link>
                            <el-link type="primary" @click="DeCharge(slot.row,scope.row)">退费</el-link>
                          </template>
                        </el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="mc_name" label="商家名称"></el-table-column>
            <el-table-column align="center" prop="mc_contacts" label="联系人"></el-table-column>
            <el-table-column align="center" prop="mc_phone" label="联系方式"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-link type="primary" @click="UserClick(scope.row)">操作员</el-link>
                    <el-link type="primary" @click="EditClick(scope.row)">修改</el-link>
                    <el-link type="danger" @click="DelClick(scope.row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :title="title" :visible.sync="dialogVisible" :width='screenWidth<738?"50%":(screenWidth<1200?"40%":"30%")' :append-to-body='true'>
            <el-form :model="merForm" :rules="rules" ref="merForm" label-width="100px" size='medium'>
                <el-form-item label="商家名称" prop="mc_name">
                    <el-input v-model="merForm.mc_name"></el-input>
                </el-form-item>
                <el-form-item label="联系人">
                    <el-input v-model="merForm.mc_contacts"></el-input>
                </el-form-item>
                <el-form-item label="联系方式">
                    <el-input v-model="merForm.mc_phone"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog v-if="userDialogVisible" :title="title" :visible.sync="userDialogVisible" width='80%' :append-to-body='true'>
            <merUser :beglongId='merId' :userType="6"/>
            <span slot="footer" class="dialog-footer">
                <el-button @click="userDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog v-if="rechargeDialogVisible" :title="title" :visible.sync="rechargeDialogVisible" width='45%' :append-to-body='true'>
            <el-form :model="rechargeForm" :rules="rules" ref="rechargeForm" label-width="100px" size='medium'>
                <el-form-item  :label="rechargeForm.opt_type===0?'充值数量':'退款数量'" prop="num">
                  <el-input v-model.number="Price" style="width:100%" ></el-input>
                </el-form-item>
                <el-form-item :label="rechargeForm.opt_type===0?'应收金额':'应退金额'" >
                    <el-input-number v-model="rechargeForm.shold_charge" controls-position="right" :disabled='true' :precision="2" :min="0" style="width:100%"></el-input-number>
                </el-form-item>
                <el-form-item :label="rechargeForm.opt_type===0?'实收金额':'实退金额'" prop="charge">
                    <el-input-number v-model="rechargeForm.charge" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="rechargeDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="Save">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import util from '../../util/util'
import merUser from '../../components/servicer/SerUser.vue'

export default {
  data: function () {
    return {
      screenWidth: 0,
      MerListData: [],
      DisRulesDataList: [],
      dialogVisible: false,
      title: '',
      merForm: {
        mc_id: 0,
        mc_name: '',
        mc_contacts: '',
        mc_phone: ''
      },
      rules: {
        mc_name: [{ required: true, message: '请输入商家名称', trigger: 'blur' }],
        num: [{ required: true, message: '请输入充值数量' }, { type: 'number' }],
        charge: [{ type: 'number', required: true, message: '请输入实收金额', trigger: 'blur' }]
      },
      userDialogVisible: false,
      merId: 0,
      rechargeDialogVisible: false,
      rechargeForm: {
        opt_type: 0,
        md_id: 0,
        mc_id: 0, // 商家
        dr_id: 0, // 折扣
        num: 0,
        shold_charge: 0.00,
        charge: 0.00
      }
    }
  },
  components: {
    merUser
  },
  created () {
    this.screenWidth = document.body.clientWidth
    this.GetMerDataList()
  },
  computed: {
    Price: {
      get () {
        return this.rechargeForm.num
      },
      set (value) {
        this.rechargeForm.num = value
        var arr = this.DisRulesDataList.filter(a => a.dr_id === this.rechargeForm.dr_id)
        this.rechargeForm.shold_charge = this.rechargeForm.num * arr[0].dr_price
      }
    },
    CheckQuota () {
      return function (merRow, disRow) {
        var value = 0
        if (merRow.disDataList !== null && merRow.disDataList.length > 0) {
          const arr = merRow.disDataList.filter(a => a.md_dr_id === disRow.dr_id)
          if (arr.length > 0) { value = arr[0].md_remain_value }
        }
        return value
      }
    }
  },
  methods: {
    GetMerDataList () {
      util.Get('merchant/getmerchantdatalist').then(res => {
        if (res.rpStatus === 10000) {
          this.MerListData = []
          if (res.list[0].length > 0) {
            res.list[0].forEach(item => {
              var arr = this.MerListData.filter(a => a.mc_id === item.mc_id)
              if (arr.length > 0) {
                arr[0].disDataList.push({
                  md_id: item.md_id,
                  md_dr_id: item.md_dr_id,
                  md_remain_value: item.md_remain_value
                })
              } else {
                var form = {
                  mc_contacts: item.mc_contacts,
                  mc_id: item.mc_id,
                  mc_name: item.mc_name,
                  mc_phone: item.mc_phone,
                  disDataList: []
                }
                form.disDataList.push({
                  md_id: item.md_id,
                  md_dr_id: item.md_dr_id,
                  md_remain_value: item.md_remain_value
                })
                this.MerListData.push(form)
              }
            })
          }
          this.DisRulesDataList = res.list[1]
        }
      })
    },
    AddClick () {
      if(util.CheckUserAuth('3-2-1')){
        this.dialogVisible = true
        this.title = '新增'
        this.merForm.mc_id = 0
        this.merForm.mc_name = ''
        this.merForm.mc_contacts = ''
        this.merForm.mc_phone = ''
      }
    },
    EditClick (row) {
      if(util.CheckUserAuth('3-2-2')){
        this.merForm = JSON.parse(JSON.stringify(row))
        this.dialogVisible = true
        this.title = '修改'
      }
    },
    DelClick (row) {
      if(util.CheckUserAuth('3-2-3')){
        this.$confirm('确定删除商家“' + row.mc_name + '”？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          util.Get('merchant/del?id=' + row.mc_id+'&name='+row.mc_name).then(res => {
            if (res.rpStatus === 10000) { this.GetMerDataList() } else { this.$message.error(res.rpMsg) }
          })
        }).catch(e => e)
      }
    },
    submit () {
      this.$refs.merForm.validate((valid) => {
        if (valid) {
          util.Post('merchant/edit', this.merForm).then(res => {
            if (res.rpStatus === 10000) {
              this.GetMerDataList()
              this.dialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },
    UserClick (row) {
      if(util.CheckUserAuth('3-2-4')){
        this.userDialogVisible = true
        this.title = '操作员'
        this.merId = row.mc_id
      }
    },
    ReCharge (merRow, disRow) {
      if(util.CheckUserAuth('3-2-5')){
        this.rechargeDialogVisible = true
        this.title = '充值'
        this.rechargeForm.opt_type = 0
        this.rechargeForm.md_id = 0
        var arr = merRow.disDataList.filter(a => a.md_dr_id === disRow.dr_id)
        if (arr.length > 0) { this.rechargeForm.md_id = arr[0].md_id }
        this.rechargeForm.mc_id = merRow.mc_id
        this.rechargeForm.dr_id = disRow.dr_id
        this.rechargeForm.num = 0
        this.rechargeForm.shold_charge = 0.00
        this.rechargeForm.charge = 0.00
      }
    },
    DeCharge (merRow, disRow) {
      if(util.CheckUserAuth('3-2-6')){
        this.rechargeDialogVisible = true
        this.rechargeForm.opt_type = 1
        this.title = '退费'
        this.rechargeForm.md_id = 0
        var arr = merRow.disDataList.filter(a => a.md_dr_id === disRow.dr_id)
        if (arr.length > 0) { this.rechargeForm.md_id = arr[0].md_id }
        this.rechargeForm.mc_id = merRow.mc_id
        this.rechargeForm.dr_id = disRow.dr_id
        this.rechargeForm.num = 0
        this.rechargeForm.shold_charge = 0.00
        this.rechargeForm.charge = 0.00
      }
    },
    Save () {
      this.$refs.rechargeForm.validate((valid) => {
        if (valid) {
          util.Post('merchant/recharge', this.rechargeForm).then(res => {
            if (res.rpStatus === 10000) {
              this.GetMerDataList()
              this.rechargeDialogVisible = false
              this.$message({
                message: this.rechargeForm.opt_type === 0 ? '充值成功' : '退费成功',
                type: 'success'
              })
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.el-table{
    margin-top: 10px;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
